<template>
  <form>
    <md-card>
      <md-card-header
        class="md-card-header-icon"
      >
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-25">
            <div class="md-layout-item text-center">
              <form-uploader
                class="imgSelector"
                image-type
                @error="fireError"
                @input="imageSelected"
              >
                <div class="picture-container">
                  <div class="picture">
                    <img
                      :src="form.image_url || avatar"
                      class="picture-src"
                    >
                  </div>
                  <h6 class="description mt-3">
                    Choose Picture
                  </h6>
                </div>
              </form-uploader>
              <div class="text-center small-text">
                The image must be JPG or PNG<br>
                The dimensions must be 300x300<br>
                The size cannot be higher than 5mb
              </div>
            </div>
          </div>
          <div class="md-layout-item md-small-75">
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-33">
                <form-text
                  v-model="form.first_name"
                  label="Given Name"
                />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <form-text
                  v-model="form.last_name"
                  label="Surname"
                />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <form-text
                  v-model="form.email"
                  label="Email"
                  disabled
                />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <form-text
                  v-model="form.new_password"
                  label="New Password"
                  password
                />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <form-text
                  v-model="form.new_password_confirmation"
                  label="New Password Confirmation"
                  password
                />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-33">
                <form-text
                  v-model="form.password"
                  label="Current Password"
                  password
                />
              </div>
              <div class="md-layout-item md-size-40">
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-size-100 pt-3">
                    <label class="md-label">
                      Commision Percentage:
                    </label>
                    {{ form.commision_percentage }}%
                  </div>
                  <div class="md-layout-item md-small-size-100 md-size-100">
                    <md-switch
                      v-model="form.is_agent"
                      class="mb-0"
                      disabled
                    >
                      Is Agent
                    </md-switch>
                  </div>
                  <div class="md-layout-item md-small-size-100 md-size-100">
                    <md-switch
                      v-model="form.was_agent"
                      class="mb-2"
                      disabled
                    >
                      Was Agent
                    </md-switch>
                  </div>
                </div>
              </div>
              <div
                v-if="form.roles.length"
                class="md-layout-item md-size-60"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-size-100 pt-3">
                    <label class="md-label">
                      Roles:
                    </label>
                    <ul class="roleList">
                      <li
                        v-for="role of form.roles"
                        :key="role.id"
                      >
                        {{ role.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="md-layout-item md-size-100 text-right">
            <md-button
              class="md-raised md-success mt-4"
              @click="sendForm"
            >
              Update Profile
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <image-cropper
      v-if="showCropper"
      :img="form.image_url"
      :img-type="image_type"
      @imageCropped="imageCropped"
      @close="showCropper = false"
    />
  </form>
</template>

<script>
import {
  FormText,
  FormUploader,
} from '@/components/Inputs';
import ImageCropper from '@/components/ImageCropper.vue';

export default {
  components: {
    FormText,
    FormUploader,
    ImageCropper,
  },
  data: () => ({
    form: {
      commision_percentage: 10,
      roles: [],
      image: null,
      image_url: null,
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      new_password: null,
      new_password_confirmation: null,
    },
    showCropper: false,
    image_type: null,
  }),
  mounted() {
    this.form = {
      ...this.form,
      ...this.user,
    };
  },
  methods: {
    async sendForm() {
      let send = true;
      if (!this.form.password) {
        this.fireError('You have to send your Current Password');
        send = false;
      }
      if (this.form.new_password !== this.form.new_password_confirmation) {
        this.fireError('The New Passwords are not the same');
        send = false;
      }

      if (send) {
        const fd = new FormData();
        if (this.form.commision_percentage) fd.append('commision_percentage', this.form.commision_percentage);
        if (this.form.image) fd.append('image', this.form.image);
        if (this.form.first_name) fd.append('first_name', this.form.first_name);
        if (this.form.last_name) fd.append('last_name', this.form.last_name);
        if (this.form.email) fd.append('email', this.form.email);
        if (this.form.new_password) fd.append('new_password', this.form.new_password);
        if (this.form.new_password_confirmation) fd.append('new_password_confirmation', this.form.new_password_confirmation);
        fd.append('password', this.form.password);

        this.request('profile', 'put', fd, (data) => {
          this.fireSuccess(data);
          this.cleanSession();
          this.$router.push({ name: 'Login' }).catch(() => {});
        });
      }
    },
    imageSelected(imageFile) {
      const imageURL = URL.createObjectURL(imageFile);
      this.form.image = imageFile;
      this.form.image_url = imageURL;
      this.showCropper = true;
      this.imageType = imageFile.type;
      this.$forceUpdate();
    },
    imageCropped(blob) {
      const imageURL = URL.createObjectURL(blob);

      // Convert blob to file
      const file = blob;
      file.lastModifiedDate = new Date();
      file.name = 'image';

      this.form.image = file;
      this.form.image_url = imageURL;
    },
  },
};
</script>

<style lang="scss">
.imgSelector {

  img {
    border-radius: 50%;
    border: 4px solid #cccccc;

    &:hover {
      border-color: #4caf50;
      cursor: pointer;
    }
  }

  .picture {
    display: inline-block;
  }

  .description {
    margin-bottom: 1em;
  }
}
.small-text { font-size: .85em; line-height: 1.2em; }

.roleList {
  margin-top: .75em;
  padding-left: 1em;

  li {
    text-transform: uppercase;
  }
}
</style>
